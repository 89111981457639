<template>
  <div>
    <b-tabs align="left" class="tab-primary" v-model="tabIndex">
      <b-tab>
        <template #title>
          <div class="tab-title">
            <span> {{$t('Historial de estados')}} </span>
          </div>
        </template>
        <history-status
        @getDelivery="getDelivery()"
        :showChangeStatus="showChangeStatus"
        :dataAccount.sync="dataAccount"
        ></history-status>
      </b-tab>
      <b-tab>
        <template #title>
          <div class="tab-title">
            <span> {{$t('Info del envío')}} </span>
          </div>
        </template>
        <delivery-info 
        :warehouses="warehouses"
        :carriers="carriers"
        @getDelivery="getDelivery()"
        ></delivery-info>
      </b-tab> 
      <b-tab v-if="showTabTicket">
        <template #title>
          <div class="tab-title">
            <span> {{$t('Tickets')}} </span>
          </div>
        </template>
        <create-view-ticket source="delivery"></create-view-ticket>
      </b-tab>
      <b-tab v-if="isUserAdmin">
        <template #title>
          <div class="tab-title">
            <span> {{$t('Avanzado')}} </span>
          </div>
        </template>
        <advance :delivery="currentDelivery"></advance>
      </b-tab>
      <b-tab v-if="isUserAdmin">
        <template #title>
          <div class="tab-title">
            <span> {{$t('Log Tracking')}} </span>
          </div>
        </template>
        <log-tracking></log-tracking>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import DeliveryInfo from './tabs/DeliveryInfo.vue'
import HistoryStatus from './tabs/HistoryStatus.vue'
import Advance from './tabs/Advance.vue'
import LogTracking from './tabs/LogTracking.vue'
import CreateViewTicket from '@/views/modules/tickets/components/CreateTickets/CreateViewTicket.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'tabs',
  components: { HistoryStatus, DeliveryInfo, LogTracking, Advance, CreateViewTicket },
  props: {
    warehouses: { type: Array, default: () => ([])},
    carriers: { type: Array, default: () => ([])},
    showChangeStatus: { type: Boolean },
    dataAccount: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      tabIndex: 0,
      allowEditForm: true
    }
  },
  computed: {
    ...mapGetters({
      currentDelivery: 'getDeliveryDetails',
      mySession: 'getSession'
    }),
    showTabTicket() {
      return !['pudo'].includes(this.mySession?.role) 
        && (this.currentDelivery?.delivery?.marketplace_id !== 1162 || [6, 32].includes(this.currentDelivery?.delivery?.carrier_id))
        && (this.currentDelivery?.delivery?.easypoint === 1 || this.currentDelivery?.can_create_ticket || this.currentDelivery?.delivery?.ticket_force_creation || this.currentDelivery?.delivery?.contract_type === 'distribucion' || this.currentDelivery?.delivery?.contract_type === 'pasarela-pro')
    },
    isUserAdmin() {
      return ['admin']?.includes(this.mySession?.role)
    }
  },
  methods: {
    ifEditForm () {
      (!this.allowEditForm) ? this.allowEditForm = true : this.allowEditForm = false
    },
    getDelivery() {
      this.$emit('getDelivery')
    }
  }

}
</script>